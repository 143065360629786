<template>
  <div class="commimssion-statement-item">
    <van-cell title="提成日期" :value="commissionStatement.stat_date" />
    <van-cell title="提成类型" :value="commissionStatement.type === 1 ? '销售' : '退货'" />
    <div class="goods">
      <van-image class="image" :src="commissionStatement.product?.image" />
      <div class="info">
        <div class="label">{{ commissionStatement.product?.name }}</div>
        <div class="sub-label amount-color">计算金额：￥{{ commissionStatement.amount }}</div>
        <div class="sub-label">提成率：{{ (commissionStatement.rate * 100).toFixed(2) }}%</div>
        <div class="sub-label amount-color">提成金额：￥{{ commissionStatement.commission_amount }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const salesTypeMaps = {
  1: '普通销售',
  2: '直播销售',
  3: '业务销售',
  4: '特惠购销售'
}
export default {
  filters: {
    salesTypeFilter(salesType) {
      return salesTypeMaps[salesType]
    }
  },
  props: {
    commissionStatement: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .goods {
    display: flex;
    background-color: white;
    padding: 10px;
  }
  .image {
    width: 80px;
    height: 80px;
  }
  .info {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .sub-label {
    font-size: 13px;
    color: #989898;
  }
</style>
