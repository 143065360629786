<template>
  <div class="commimssion-statement-item">
    <van-cell title="提成日期" value-class="stat-date">
      <span v-if="commissionStatement.min_stat_date">
        {{ commissionStatement.min_stat_date }}
      </span>
      <span v-if="commissionStatement.min_stat_date && commissionStatement.max_stat_date && commissionStatement.max_stat_date !== commissionStatement.min_stat_date">-</span>
      <span v-if="commissionStatement.max_stat_date && commissionStatement.max_stat_date !== commissionStatement.min_stat_date">
        {{ commissionStatement.max_stat_date }}
      </span>
    </van-cell>
    <div class="goods">
      <van-image class="image" :src="commissionStatement.product?.image" />
      <div class="info">
        <div class="label">{{ commissionStatement.product?.name }}</div>
        <div class="sub-label amount-color">计算金额：￥{{ commissionStatement.amount }}</div>
        <div class="sub-label">
          提成率：
          <span v-if="commissionStatement.min_rate">
            {{ (commissionStatement.min_rate * 100).toFixed(2) }}%
          </span>
          <span v-if="commissionStatement.min_rate && commissionStatement.max_rate && commissionStatement.max_rate !== commissionStatement.min_rate">-</span>
          <span v-if="commissionStatement.max_rate && commissionStatement.max_rate !== commissionStatement.min_rate">
            {{ (commissionStatement.max_rate * 100).toFixed(2) }}%
          </span>
        </div>
        <div class="sub-label amount-color">提成金额：￥{{ commissionStatement.commission_amount }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const salesTypeMaps = {
  1: '普通销售',
  2: '直播销售',
  3: '业务销售',
  4: '特惠购销售'
}
export default {
  filters: {
    salesTypeFilter(salesType) {
      return salesTypeMaps[salesType]
    }
  },
  props: {
    commissionStatement: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .goods {
    display: flex;
    background-color: white;
    padding: 10px;
  }
  .image {
    width: 80px;
    height: 80px;
  }
  .info {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .sub-label {
    font-size: 13px;
    color: #989898;
  }
  .stat-date {
    flex: 3;
  }
</style>
