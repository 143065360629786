var render = function () {
  var _vm$commissionStateme, _vm$commissionStateme2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "commimssion-statement-item"
  }, [_c('van-cell', {
    attrs: {
      "title": "提成日期",
      "value": _vm.commissionStatement.stat_date
    }
  }), _c('van-cell', {
    attrs: {
      "title": "提成类型",
      "value": _vm.commissionStatement.type === 1 ? '销售' : '退货'
    }
  }), _c('div', {
    staticClass: "goods"
  }, [_c('van-image', {
    staticClass: "image",
    attrs: {
      "src": (_vm$commissionStateme = _vm.commissionStatement.product) === null || _vm$commissionStateme === void 0 ? void 0 : _vm$commissionStateme.image
    }
  }), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s((_vm$commissionStateme2 = _vm.commissionStatement.product) === null || _vm$commissionStateme2 === void 0 ? void 0 : _vm$commissionStateme2.name))]), _c('div', {
    staticClass: "sub-label amount-color"
  }, [_vm._v("计算金额：￥" + _vm._s(_vm.commissionStatement.amount))]), _c('div', {
    staticClass: "sub-label"
  }, [_vm._v("提成率：" + _vm._s((_vm.commissionStatement.rate * 100).toFixed(2)) + "%")]), _c('div', {
    staticClass: "sub-label amount-color"
  }, [_vm._v("提成金额：￥" + _vm._s(_vm.commissionStatement.commission_amount))])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }